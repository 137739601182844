.rowlogin{
	//height: calc(100vh - 56px);
	height: calc(100vh);
    background-color: #FAFAFA;
}
.no-select-av{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}



//Card Login - DiseÃ±o de panel para formulario de entrada

.cardlogin{
    // height: 408.19px;
    height: 100%;
	font-size: 14px;
	padding-top: 36px;
    padding-bottom: 36px;
    .pt10{
        padding-top: 10px;
    }
    #section1{
        height: 200px !important;
    }
	a, .btn{
		font-weight: bold;
		letter-spacing: 0.15px;
	}
	br{
		margin-bottom: 16px;
    }
    .fp-tableCell{
        vertical-align: baseline;
    }
	>.card-header{
		text-align: center;
		background-color: white;
		border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
		border-bottom: none;
		font-size: 24px;
		strong{
			font-weight: 400;
			padding-top: 16px;
		}
	}
	label{
		position: absolute;
        top: 12px;
        margin-bottom: 0;
		left: 0;
		padding-left: 27px;
		-webkit-transition: .1s ease-out;
		-o-transition: .1s ease-out;
		transition: .1s ease-out;
		cursor: text;
		color:#757575;
		font-size: 1.03rem;
		// &:focus{
		// 	color:yellow;
		// }
		&.active{
			color: #1a73e8;
			top: 10px;
			font-size: 13px;
			background-color:white;
			margin-left: 25px;
			padding: 0 5px;
			-webkit-transform: translateY(-100%);
			-ms-transform: translateY(-100%);
			transform: translateY(-100%);
			// background: #fff;
			font-weight: 500;
			// padding-right: 5px;
			// padding-left: 5px;
			// font-size: 11px;
			// left: 8px;
		}
	}
	input:focus{
		border-color: #1a73e8;
		box-shadow: none;
		border-width: 2px;
	}
	>.card-body{
		padding-right: 40px;
		padding-left: 40px;
		padding-bottom: 40px;
	}
	border: 1px solid #dadce0;
	.form-control{
		border: 1px solid #dadce0;
		height: calc(2.2em + 0.75rem + 2px);
	}
	&.card{
		border-radius: 8px;
	}
	.inlinebuttons{
		button{
			position: absolute;
			right: 15px;
			padding-top: 8px;
			padding-bottom: 8px;
			padding-left: 16px;
			padding-right: 16px;
		}
		.btn{
			color: inherit;
			font-size: 14px;
			
			&.btn-success{
				background-color:#1a73e8;
				border-color: #1a73e8;
				color: #fff;
			}
		}
		a.nav-link{
			display: inline-block;
			color:#1a73e8;
			padding-left: 0;
        }
        .btnnext{
            position: absolute;
            right: 18px;
            padding-top: 8px;
			padding-bottom: 8px;
			padding-left: 16px;
			padding-right: 16px;
        }
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    // border: 1px solid white;
    // -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
    transition: background-color 5000s ease-in-out 0s;
    }
    a:active, a:active * { outline: none !important; -moz-outline-style: none !important;box-shadow: none; }
    a:focus, a:focus * { outline: none !important; -moz-outline-style: none !important;box-shadow: none; }
    
    .failed-validation{
        border-color: red;
        border: 2px solid red;
    }
	 
	.navbarbutton{
		padding-left: 10px;
		padding-right: 10px;
	}
}


/*form styles*/
#msform {
	width: 400px;
	margin: 50px auto;
	text-align: center;
	position: relative;
}
#msform fieldset {
	background: white;
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
	padding: 20px 30px;
	box-sizing: border-box;
	width: 80%;
	margin: 0 10%;
	
	/*stacking fieldsets above each other*/
	position: relative;
}
/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
	display: none;
}
/*inputs*/
#msform input, #msform textarea {
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin-bottom: 10px;
	width: 100%;
	box-sizing: border-box;
	font-family: montserrat;
	color: #2C3E50;
	font-size: 13px;
}
/*buttons*/
#msform .action-button {
	width: 100px;
	background: #27AE60;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 1px;
	cursor: pointer;
	padding: 10px 5px;
	margin: 10px 5px;
}
#msform .action-button:hover, #msform .action-button:focus {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #27AE60;
}
/*headings*/
#msform{
	.fs-title {
		font-size: 15px;
		text-transform: uppercase;
		color: #2C3E50;
		margin-bottom: 10px;
	}
	.fs-subtitle {
		font-weight: normal;
		font-size: 13px;
		color: #666;
		margin-bottom: 20px;
	}
	/*progressbar*/
	#progressbar {
		margin-bottom: 30px;
		overflow: hidden;
		/*CSS counters to number the steps*/
		counter-reset: step;
	}
	#progressbar li {
		list-style-type: none;
		color: white;
		text-transform: uppercase;
		font-size: 9px;
		width: 33.33%;
		float: left;
		position: relative;
	}
	#progressbar li:before {
		content: counter(step);
		counter-increment: step;
		width: 20px;
		line-height: 20px;
		display: block;
		font-size: 10px;
		color: #333;
		background: white;
		border-radius: 3px;
		margin: 0 auto 5px auto;
	}
	/*progressbar connectors*/
	#progressbar li:after {
		content: '';
		width: 100%;
		height: 2px;
		background: white;
		position: absolute;
		left: -50%;
		top: 9px;
		z-index: -1; /*put it behind the numbers*/
	}
	#progressbar li:first-child:after {
		/*connector not needed before the first step*/
		content: none; 
	}
	/*marking active/completed steps green*/
	/*The number of the step and the connector before it = green*/
	#progressbar li.active:before,  #progressbar li.active:after{
		background: #27AE60;
		color: white;
	}
}